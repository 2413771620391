var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"angststoerungen"},[_c('page-title',{attrs:{"slug":"angststoerungen","alt":"Die Behandlung von Angststörungen","img-object-position":"70% 50%"}},[_vm._v(" Die Behandlung von Angststörungen ")]),_c('v-page',[_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.angststoerungen.textVorBild)}}),_c('div',{staticClass:"content-img"},[_c('responsive-image',{attrs:{"urls":{
          webp: [
            { size: 640, url: require('@/assets/img/angst/angst_640.webp') },
            { size: 800, url: require('@/assets/img/angst/angst_800.webp') },
            { size: 960, url: require('@/assets/img/angst/angst_960.webp') },
            { size: 1280, url: require('@/assets/img/angst/angst_1280.webp') },
            { size: 1600, url: require('@/assets/img/angst/angst_1600.webp') },
            { size: 1920, url: require('@/assets/img/angst/angst_1920.webp') } ],
          jpg: [
            { size: 640, url: require('@/assets/img/angst/angst_640.jpg') },
            { size: 800, url: require('@/assets/img/angst/angst_800.jpg') },
            { size: 960, url: require('@/assets/img/angst/angst_960.jpg') },
            { size: 1280, url: require('@/assets/img/angst/angst_1280.jpg') },
            { size: 1600, url: require('@/assets/img/angst/angst_1600.jpg') },
            { size: 1920, url: require('@/assets/img/angst/angst_1920.jpg') } ],
        },"sizes":"(min-width: 1280px) min(720px, min(calc(100vw - 128px), 1280px)), min(720px, max(80vw, calc(100vw - 128px)))","alt":"Angst"}})],1),_c('article',{staticClass:"article--2 text hyphenate",domProps:{"innerHTML":_vm._s(_vm.angststoerungen.textNachBild)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }