<template>
  <div class="angststoerungen">
    <page-title
      slug="angststoerungen"
      alt="Die Behandlung von Angststörungen"
      img-object-position="70% 50%"
    >
      Die Behandlung von Angststörungen
    </page-title>
    <v-page>
      <article class="text hyphenate" v-html="angststoerungen.textVorBild"></article>

      <div class="content-img">
        <responsive-image
          :urls="{
            webp: [
              { size: 640, url: require('@/assets/img/angst/angst_640.webp') },
              { size: 800, url: require('@/assets/img/angst/angst_800.webp') },
              { size: 960, url: require('@/assets/img/angst/angst_960.webp') },
              { size: 1280, url: require('@/assets/img/angst/angst_1280.webp') },
              { size: 1600, url: require('@/assets/img/angst/angst_1600.webp') },
              { size: 1920, url: require('@/assets/img/angst/angst_1920.webp') },
            ],
            jpg: [
              { size: 640, url: require('@/assets/img/angst/angst_640.jpg') },
              { size: 800, url: require('@/assets/img/angst/angst_800.jpg') },
              { size: 960, url: require('@/assets/img/angst/angst_960.jpg') },
              { size: 1280, url: require('@/assets/img/angst/angst_1280.jpg') },
              { size: 1600, url: require('@/assets/img/angst/angst_1600.jpg') },
              { size: 1920, url: require('@/assets/img/angst/angst_1920.jpg') },
            ],
          }"
          sizes="(min-width: 1280px) min(720px, min(calc(100vw - 128px), 1280px)), min(720px, max(80vw, calc(100vw - 128px)))"
          alt="Angst"
        ></responsive-image>
      </div>

      <article class="article--2 text hyphenate" v-html="angststoerungen.textNachBild"></article>
    </v-page>
  </div>
</template>

<script>
import axios from 'axios'

import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'Angststoerungen',
  components: {
    PageTitle,
    VPage,
    ResponsiveImage,
  },
  data() {
    return {
      angststoerungen: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get('/angststoerungen')
    next(vm => (vm.angststoerungen = res.data.data))
  },
}
</script>

<style scoped>
.article--2 {
  margin: calc(var(--font-size-regular) * 4) auto 0;
}

.content-img {
  width: var(--text-width);
}
</style>
